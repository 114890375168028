'use client';
import { observer } from 'mobx-react-lite';
import { useIsClient } from 'usehooks-ts';

import { Skeleton } from '@/shared/ui';
import { Tabs } from '@/shared/ui/tabs';
import { useStore } from '@/store/context';

import styles from './live-feed.module.scss';
export const LiveFeedSortingTabs = observer(() => {
  const liveFeed = useStore()?.livefeed;
  const liveFeedSort = useStore()?.livefeed.liveFeedSort;
  const isClient = useIsClient();
  const isHydrated = liveFeed?.isHydrated;

  return isClient && isHydrated ? (
    <Tabs<'all' | 'exclusive'>
      className={styles['tabs-container']}
      defaultActiveTab={liveFeedSort}
      onTabsChange={tab => {
        tab && liveFeed?.setLiveFeedStore(tab);
      }}
    >
      <Tabs.ItemFilter value={'all'}>All</Tabs.ItemFilter>
      <Tabs.ItemFilter value={'exclusive'}>Exclusive</Tabs.ItemFilter>
    </Tabs>
  ) : (
    <div className={styles['live-feed-tabs-skeleton']}>
      <Skeleton borderRadius={8} style={{ height: '100%' }} />
    </div>
  );
});
