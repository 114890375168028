'use client';

import { observer } from 'mobx-react-lite';
import React from 'react';

import { useGetFilterTabs } from '@/shared/hooks/client/use-get-filter-tabs';
import { Portal } from '@/shared/portal';
import { HeaderFilterTab } from '@/shared/ui/header-filter-tab';
import { useStore } from '@/store/context';

import styles from './header-tabs.module.scss';

export const HeaderTabsFeatureMobile = observer(() => {
  const appStore = useStore().app;
  const game = appStore.getGame;
  const getTabs = useGetFilterTabs();
  const saleTabs = getTabs('hot-sale', game);
  const propertyTabs = getTabs('properties', game);
  const categoryTabs = getTabs('categories', game);

  return (
    <Portal selector={'#header-portal'}>
      <div>
        <nav className={styles['filters']} aria-label="filters-menu">
          <ul>
            {saleTabs.map(tab => (
              <HeaderFilterTab
                name={tab.name}
                href={tab.href}
                variant={tab.variant}
                key={tab.name}
                icon={tab.icon}
              />
            ))}
            {categoryTabs.map(tab => (
              <HeaderFilterTab
                name={tab.name}
                href={tab.href}
                variant={tab.variant}
                key={tab.name}
                icon={tab.icon}
              />
            ))}
            {propertyTabs.map(tab => (
              <HeaderFilterTab
                name={tab.name}
                href={tab.href}
                variant={tab.variant}
                key={tab.name}
                icon={tab.icon}
              />
            ))}
          </ul>
        </nav>
      </div>
    </Portal>
  );
});
