import cn from 'clsx';
import Link from 'next/link';
import React from 'react';

import { Typography } from '@/shared/ui';
import { AllIcons, Illustration } from '@/shared/ui/illustration/illustration';

import styles from './header-filter-tab.module.scss';
export type FilterItemVariants = 'secondary' | 'tertiary' | 'primary';
export const HeaderFilterTab = ({
  variant,
  href,
  name,
  icon,
}: {
  variant?: FilterItemVariants;
  href?: string;
  icon?: keyof AllIcons;
  name?: string;
}) => {
  const filterClasses = cn(styles['filter-tab'], styles[`variant--${variant}`]);

  return (
    <li>
      <Link href={href || '/shop'} className={filterClasses}>
        {icon && (
          <Illustration
            spanTagClassName={styles['filter-tab--icon']}
            name={icon}
            id={'filter-tab--icon'}
            spriteName={'icons'}
          />
        )}
        <Typography className={styles['filter-tab--text']}>{name}</Typography>
      </Link>
    </li>
  );
};
